option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}
.email-disc{
font-size: 0.8rem;
}
.crt-post-alert {
font-size: 1rem!important;
  color: #000;
  padding-left: 0 !important;
  font-weight: 300;
    padding-left: 20px;
    margin-top: 7px;
    display: inline-block;
}
/*------------------------{posted}-------------------------*/
.post-container {
  text-align: center;
}
.posted-card {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #f8faf5;
  margin: 0 auto;
  float: left;
}
.posted-msg {
  font-size: 1.2rem;
  margin-left: 20vw;
}
.checkmark {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
}

.post-card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  margin-top: 100px;
}

/*------------------------{posted}-------------------------*/
.fa-arrow-right {
  margin-left: 5px;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.post-opt-outer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.post-opt-inner {
  width: 60vw;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-color: #f1f6f9 !important;
}

.post-opt-btn {
  float: right;
  margin-left: 20px;
  /* background-color: #272343 !important; */
}

.post-opt {
  margin-top: 40px;
  display: inline-block;
}

.btn-head {
font-size: 1.6rem;
font-weight: 300;
  padding-left: 20px;
  margin-top: 7px;
  display: inline-block;
}

.ql-heading {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  text-decoration: underline 2px;
  font-size: 2.5rem !important;
  font-family: "Montserrat", sans-serif;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.asterik {
  color: #cf0000;
}
.label-post {
  color: #000 !important;
  font-weight: bold;
}

.post-btn {
  background-color: #5c6e91;
}
.post-btn:hover {
  background-color: #5c6e90;
}

input .form-control {
  line-height: 1.1;
  padding-bottom: 10px;
  height: 40px !important;
}

.createPost {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  text-align: center;
}

.signin {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  text-align: center;
}

.signin-btn {
  margin-top: 20px;
  background-color: #440a67;
  border-color: #440a67;
  margin-left: 35vw;
  margin-right: auto;
  width: auto;
  background-color: #52057b;
  border-color: #52057b;
  margin-bottom: 50px;
}

.signin-btn:hover {
  background-color: #440a67;
  border-color: #440a67;
}

.signin-btn::after {
  background-color: #440a67;
  border-color: #440a67;
}

.signin-form {
  display: inline-block;
  padding-bottom: 50px;
  align-items: center;
  text-align: left;
}

.logout-btn {
  margin-top: 50vh;
  width: 100px;
  margin-right: 10vw;
  margin-left: 80vw;
  text-align: right;
}

.label-form {
  font-size: 1.2rem;
  margin-left: 50px;
  margin-bottom: 40px;
}

.signup-form {
  display: inline-block;
  padding-bottom: 50px;
  align-items: center;
  text-align: left;
}

.signup-btn {
  margin-top: 20px;
  margin-left: 35vw;
  margin-right: auto;
  width: auto;
}

.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.inner {
  width: 60vw;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
@media (max-width: 1024px) {
  .posted-msg {
    font-size: 1rem;
    margin-left: 25vw;
  }
}
@media (max-width: 800px) {
  .posted-msg {
    font-size: 1rem;
    margin-left: 0;
  }
  .posted-card {
    float: none;
  }
}

@media (max-width: 600px) {
  .inner {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
  .post-opt-inner {
    width: 90vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .post-opt-btn {
    float: none;
    margin-left: 10px;
    /* background-color: #87a7b3 !important; */
  }
}
@media (min-width: 2300px) {
  .post-opt-inner {
    width: 50vw !important;
  }
}
