.about-cont {
  justify-content: flex-start;
color:#000;
font-weight: 300;
}

.about-card-1 {
  background-color: #fcfefe !important;
  color: #000;
  padding-bottom: 40px;
  text-align: justify !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.about-card-2 {
  background-color: #fcfefe !important;
  color: #000;
  padding-bottom: 40px;
  text-align: justify !important;
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.about-card-body-1 {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif !important;
  line-height: 1.5;
  margin-top: 20px;
}
.about-card-body-2 {
  font-size: 1.2rem;
  font-family: "Roboto", sans-serif !important;
  line-height: 1.5;
  margin-top: 20px;
}
/* .about-card-head-1 {
  color: #000;
  font-size: 2rem;
  margin-left: 30px;
  font-family: "Montserrat", sans-serif;
} */
.about-head {
  float: left;
  margin-top: 60px;
  margin-bottom: 60px;
  text-decoration: underline 2px;
  font-size: 2.5rem !important;
  font-family: "Montserrat", sans-serif;
}
.about-subhead {
  font-size: 1.3rem;
  font-weight: bold;
}
.about-href{
font-weight: 350;
}
.about-quote {
  margin-top: 40px !important;
  font-size: 1.5rem;
  text-align: center !important;
  font-family: "Montserrat", sans-serif;
}
@media (max-width: 700px) {
  .about-card-body-1 {
    font-size: 0.9rem;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .about-card-body-2 {
    font-size: 0.9rem;

    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
