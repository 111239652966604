.verify {
  margin-top: 100px;
}
.bg-secondary {
  background-color: #5c6e91 !important;
}
.badge {
  margin-top: 30px;
  display: inline-block;
  padding: 15px 15px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
}
.del-btn-ver{
background-color: #5c6e91;
}
.del-btn-ver:hover{
background-color: #5c6e91;
}
.verify-head {
  margin-bottom: 30px;
}
.del-container {
  margin-top: 100px;
}

.search-input {
  width: 250px !important;
  height: 45px;
  border-radius: 25px;
}
.del-search {
  text-align: center;
font-size: 0.85rem!important;
}
.del-head {
  text-align: center;
  margin-bottom: 50px;
  text-decoration: underline;
}
@media (max-width: 900px) {
  .search-input {
    width: 180px !important;
  }
}
@media (max-width: 750px) {
  .search-input {
    width: 50vw !important;
  }
}
