html {
  position: relative;
  min-height: 100%;
}
body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  -moz-osx-font-smoothing: grayscale;
}
div {
  font-family: "Roboto";
}
button {
  font-family: "Roboto";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn {
  font-family: "Roboto";
}
