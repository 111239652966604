body {
  background: #fff;
}
.heart {
  color: #fff;
}
.fa-linkedin{
margin-left: 10px!important;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #5c6e91;
  color: #fff;
  margin-left: auto;
  margin-right: 0;
  height: auto;
  font-size: 2rem;
}
.post-opt-btn {
  background-color: #5c6e91 !important;
}
.footer-cont {
  width: 100%;
  padding-top: 10px;
  text-align: center;
}
.footer-quote {
  font-family: "Montserrat", sans-serif;
  font-weight: 300!important;
  font-size: 1.1rem;
  font-weight: 200;
  margin-bottom: 0;
}
/* .quote-by {
  float: right;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 1.1rem;
  font-weight: 200;
} */
.footer-1 {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;

  font-weight: 200;
  margin-top: 0;
}
.footer-link{
margin-left: 5px;
      color:#fff!important;

}
.search-btn {
  background-color: #5c6e91 !important;
}
.fa-envelope {
  margin-left: 15px !important;
  color: #fff;
}
.fa-envelope:hover {
  color: #000;
}
@media (max-width: 600px) {
  .footer {
    height: auto;
  }
  .footer-cont {
    width: 100%;
    padding-top: 30px;
    height: auto;
    text-align: center;
  }
}
