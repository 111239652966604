/* .CircleLoader {
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  top: 50%;
  bottom: 50%;
}
.loader {
  position: absolute;
} */
.loading-text {
  position: fixed !important;
  top: 45% !important;
  left: 45%;
}
@media (max-width: 700px) {
  .circleloader {
    left: 20% !important;
  }
}
