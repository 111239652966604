.navbar {
  border-radius: 0;
  margin-bottom: 0;
}
.navbar-brand {
  margin-right: 600px;
}
.nav-link {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  padding: 0 !important;
  margin-left: 15px;
  margin-right: 15px;
}
.navbar-toggler-icon {
  background-color: #000 !important;
}

@media (max-width: 1200px) {
  .navbar-brand {
    margin-right: 500px;
  }
}
@media (max-width: 550px) {
  .navbar-brand {
    margin-right: auto;
  }
}
