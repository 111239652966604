.select-css {
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px #fff;
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;

}

.btn-head-1 {
font-size: 1.3rem;
font-weight: 300;

  padding-left: 20px;
  margin-top: 7px;
  display: inline-block;
}
/* ------------------------------------------------XXXXXXXXXXXXXXXXXXXXXX-----------------------------*/

/*-----------------------------------disc--------------------------------------------------*/
.home-disc {
  font-size: 1rem;
}
.disc-text {
  text-align: justify;
  font-family: "Montserrat";
  font-weight: 200;
  font-size: 1.2rem;
  line-height: 1.5;
}
.read-more {
  background-color: #5c6e91 !important;
}
.disc-card {
  margin-top: 120px;
  margin-bottom: 60px !important;
}
.disc-card .disc-card-header-info {
      font-size: 1.4rem!important;
font-weight: 300;
color: #fff !important;
  background: linear-gradient(60deg, #ff577f, #ff577f) !important;
  box-shadow: 0 5px 20px 0px rgb(0 0 0/ 20%),
    0 13px 24px -11px rgb(255 87 127/ 60%) !important;
}

.home-hr {
  border: none;
  margin: 100px auto 50px auto;
  border-top-style: dotted;
  color: #f0f0f0;
  border-width: 5px;
  width: 20%;
}

.read-btn-text {
  cursor: pointer;
  font-weight: bold;
  color: #000;
  font-style: "Montserrat" !important;
}

/*-----------------------------------disc--------------------------------------------------*/

.row-1 {
  margin-top: 60px;
  margin-bottom: 60px;
}
.row-2 {
  margin-top: 60px;
  margin-bottom: 600px;
}
.fas {
  margin-left: 5px;
}
.search-container {
  margin-top: 60px;
  padding-left: 0;
  padding-right: 0;
}
.form-select-lg {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  padding-left: 1rem !important;
  font-size: 0.9rem !important;
}
.search-form-select {
  margin-left: auto;
  margin-right: auto;
  width: 20vw !important;
}
.post-heading {
  font-size: 1.2rem;
  font-weight: 400;
  font-style: normal;
}
.sub-heading {
  font-weight: bold;
  color: #000;
}
.card {
  text-align: left;
}
.card-text {
  font-weight: 400;
  color: #3c4858;
}
.card-container {
  columns: 1;
  font-family: 'Roboto', sans-serif !important;
}
.card-title {
      font-family: 'Roboto', sans-serif !important;
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}
.search-input {
  width: 70vw !important;
  height: 60px;
  border-color: #000 !important;
  /* border-radius: 30px; */
  padding-left: 10px;
  padding-right: 10px;
}
.btn-cont {
  /* text-align: center; */
}
.search-btn {
      font-family: 'Roboto', sans-serif !important;
  background-color: #5c6e91 !important;
  margin-top: 0;
  width: 15vw;
  height: 35px;
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: auto;
}
.custom-control-label {
  font-weight: 400;
}
.no-post {
  margin-top: 100px;
  text-align: center;
}
.search-outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.search-inner {
  width: 80vw;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.search-outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.search-outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

/*-------------copied msg ------------*/
.copy-btn {
  width: 40px;
  /* background-color: #eaeaeb; */
  font-size: 18px;
  padding: 6px 9px;
  border-radius: 5px;
  border: none;
  color: #6c6c6c;
  margin-left: 20px;
  transition: all 0.4s;
}
.copy-btn:hover {
  transform: scale(1.3);
  color: #1a1a1a;
  cursor: pointer;
}

.copy-btn:focus {
  outline: none;
}

.copied {
  font-family: "Montserrat", sans-serif;
  width: 60px;
  float: inherit;
  margin-top: 200px !important;
  margin-left: 10px;
  color: #000;
  padding: 15px 15px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 15px #b8c6db;
  -moz-box-shadow: 0 3px 15px #b8c6db;
  -webkit-box-shadow: 0 3px 15px #b8c6db;
}

/*-----------------------------------copied msg--------------------------------*/

/*-----------------------------------------------------av-post------------------------*/
.alert {
  background-color: #aad8d3 !important ;
  color: #02475e !important;
  width: 1100px;
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: block;
}
.av-post-outer {
  margin-bottom: 25px;
}
.av-post-inner {
text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.in-post-inner {
  width: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.av-alert {
  font-size: 1.2rem;
}
/*-----------------------------------------------------av-post------------------------*/

@media (max-width: 900px) {
  .btn-cont {
    text-align: center;
    padding-left: 0;
  }
  .search-btn {
    width: 20vw;
    text-align: center;
  }
}

@media (max-width: 750px) {
  .search-input {
    width: 40vw;
    height: 50px;
    border-color: #000 !important;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .search-form-select {
    width: 50vw !important;
  }
}
@media (max-width: 600px) {
      .disc-card .disc-card-header-info {
font-size: 1.2rem!important;
}
  .search-form-select {
text-align: center!important;
    width: 59vw !important;
  }
  .row-1 {
    display: none;
  }
  .row-2 {
    margin-bottom: 30px;
  }

  .search-btn {
    width: 40vw;
  }
  .av-post-inner {
    width: 90vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .disc-text {
    font-size: 1rem;
  }
.form-select-lg{
font-size: 0.8rem!important;
}
}
@media (max-width: 500px) {
  .copy-button {
    margin-left: 5px;
  }
  .copied {
    margin-top: 0;
    padding: 15px 5px !important;
    margin-left: 0;
  }

}
@media (min-width: 601px) {
  .row-2 {
    display: none;
  }
}
@media (min-width: 1590px) {
  .search-input {
    width: 50vw;
    height: 50px;
    border-color: #000 !important;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1920px) {
  .search-input {
    width: 45vw;
    height: 50px;
    border-color: #000 !important;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 2300px) {
  .search-input {
    width: 30vw;
    height: 50px;
    border-color: #000 !important;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
